
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import './scss/ads.scss';
@import './scss/app.scss';

header {
  padding: 0 1rem;
}

.appbar-title h1 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cog-btn {
  background-color: $color-secondary;
}
