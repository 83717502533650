.theme--light.v-application {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  color: $color-primary;
}

.text--black {
  color: $ads-dark;
}

.container {
  max-width: 70rem;
  padding: 32px;

  @include mobile() {
    padding: 16px;
  }

  &--sm {
    max-width: 60.255rem;
  }

  &--xs {
    max-width: 54rem;
  }
}

.main-content {
  margin-top: 2rem;
}

p {
  font-weight: 500;
  line-height: 1.625;
}

.v-application.theme--light a {
  font-weight: bold;
  &,
  &:visited {
    color: currentColor;
  }
}

li::marker {
  font-size: 0.6em;
}

.v-application .v-btn.v-btn--text {
  height: auto;
  padding: 0;
  color: currentColor;
}

.v-application button.v-btn {
  transition-property: all;

  //ADS buttons aren't configured correctly to deal with disabled buttons
  &.v-btn--disabled {
    border: 3px solid transparent;
  }
}

// Text/Icon button focus styling
.v-application .v-btn {
  &.v-btn--text,
  &.v-btn--icon {
    &::before {
      content: none;
    }
  }

  &.v-btn--icon:focus {
    border: 2px solid currentColor;
  }

  //Mimic border outline without worry about button moving
  &.red:focus {
    box-shadow: 0 0 0 4px $color-red-dark !important;
  }
}

.v-application.theme--light {
  .v-select__slot,
  .v-text-field__slot {
    label {
      font-size: 1.25rem;
    }
  }
}

// screen reader text style
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.v-messages__message {
  font-size: 1rem;
  font-weight: 400;
}

.v-menu__content.v-autocomplete__content
  .v-list
  .v-list-item--link.v-list-item--active {
  background-color: $ads-blue-2-dark;
}
