@import '~@nswdoe/doe-ui-core/styles/variables.scss';
// COLOURS
$color-primary: #041e42;
$color-secondary: #c8dcf0;
$color-red-dark: #840009;
$color-text-body: #002040;

// BREAKPOINTS
$breakpoint-desktop: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile-lg: 667px;
$breakpoint-mobile: 480px;

$btn-sizes: (
  'default': 44
);
