@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin tablet-wide {
  @media (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin mobile-lg {
  @media (max-width: #{$breakpoint-mobile-lg}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}
